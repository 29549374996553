const cartData = $('#cart-data'),
      productModal = $('#product-modal'),
      productModalContent = $('#product-modal-content'),
      currency = cartData.data('currency');

const removeProductFunction = (ths, sendData) => {
    $.each($(`[data-product-wrapper-id=${ths.data('cartProductId')}]`), function () {
        $(this)
            .removeClass('d-flex d-block')
            .addClass('d-none');
    });

    sendData.cartProductId = ths.data('cartProductId');
};

const addTeamPassFunction = (ths, sendData) => {
    sendData.productId = ths.data('productId');
    sendData.quantity = 1;
    sendData.startDate = null;
    sendData.endDate = null;
    sendData.name = ths.data('productName');
    sendData.bundles = [
        {'productId': 4, 'quantity': $('input[name="adultTicket"]:checked').val()},
        {'productId': 3, 'quantity': $('input[name="childrenTicket"]:checked').val()}
    ];
};

const addNewProductFunction = (ths, sendData, newProduct = true) => {
    let quantity = $(`input.input-items[data-input-product-id-quantity="${ths.attr('data-product-id')}-${ths.attr('data-added-from-type')}"]`),
        startDate = ths.data('inputProductIdDateStart'),
        endDate = ths.data('inputProductIdDateEnd'),
        pavilionNumber;

    if (typeof startDate !== 'undefined') {
        if (typeof endDate !== 'undefined') {
            sendData.name = `${ths.data('productName')}`;
        } else {
            pavilionNumber = $(`select[data-pavilion-type="${ths.attr('data-pavilion-type')}"]`).find('option:selected').text().replace(/\s/g, "");
            sendData.name = `${ths.data('productName')} - ${pavilionNumber}`;
        }
    }

    if (newProduct === false) sendData.cartProductId = ths.data('cartProductId');
    sendData.productId = ths.attr('data-product-id');
    sendData.quantity = (typeof quantity !== 'undefined') ? quantity.val() : 1;
    sendData.startDate = (typeof startDate !== 'undefined') ? startDate : null;
    sendData.endDate = (typeof endDate !== 'undefined') ? endDate : null;
};

const renderDropdown = () => {
    $.ajax({
        url: cartData.data('myCartWithAllDataPath'),
        type: 'GET',
        error: function (req, err) {
            console.error(err);
        },
        success: function (data) {
            const headerItemCart = $('#header-item-cart'),
                  totalsBlock = $('#cart-totals-block'),
                  myCart = $('#cart');
            if (data.isError) {
                console.error(data.isError);
            } else {
                headerItemCart.html(data.dropdownCartHtml);
                headerItemCart.siblings('.navbar-icon-link-badge').html(data.numberOfProducts);
                totalsBlock.html(data.totalsBlockHtml);
                myCart.html(data.myCartBlockHtml);
                cartFunctions();
            }
        }
    });
};

const computeCartRequest = (dis, withReload = false) => {
    let disAction = dis.data('productAction'),
        data = {},
        url = cartData.data('addToCartPath');

    switch (disAction) {
        case 'REMOVE':
            removeProductFunction(dis, data);
            url = cartData.data('removeFromCartPath');
            break;
        case 'TEAMPASS':
            addTeamPassFunction(dis, data);
            data = JSON.stringify(data);
            break;
        case 'MODIFY':
            addNewProductFunction(dis, data, false);
            data = JSON.stringify(data);
            break;
        case 'ADD':
            addNewProductFunction(dis, data);
            data = JSON.stringify(data);
            break;
        default:
            addNewProductFunction(dis, data);
            data = JSON.stringify(data);
    }

    $.ajax({
        url: url,
        type: "POST",
        data: data,
        beforeSend: function () {
            productModalContent.empty();
        },
        error: function (req, err) {
            console.error(err);
        },
        success: function (data) {
            if (data.isError) {
                let alertBlock = $('.alert'),
                    alertBlockText = alertBlock.find('.product-name');

                alertBlock
                    .removeClass('d-none')
                    .addClass('d-block')

                alertBlockText
                    .empty()
                    .html(data.errorMessage);
                $('.input-daterange').siblings('p').hide();

                throw new Error(data.errorMessage);

            } else {

                if (dis.hasClass('buy')) productModal.modal('show');
                productModalContent.html(data.addModal);

                withReload ? location.reload() : renderDropdown();

                cartFunctions();
            }
        }
    });
};

const modifyPriceByQty = (dis) => {
    let thsInput = dis.siblings('.input-items'),
        inputVal = parseInt(thsInput.val()),
        priceSpan = $(`.nn.price[data-product-id="${dis.attr('data-real-product-id')}"]`),
        thsPrice = parseFloat(priceSpan.data('singleProductPrice'));

    isNaN(inputVal) ? priceSpan.html(`${thsPrice} ${currency}`) : priceSpan.html(`${inputVal * thsPrice} ${currency}`);
};

const addOrSubtractInput = (dis) => {
    const thsInput = dis.siblings('.input-items'),
          inputVal = parseInt(thsInput.val()),
          inputMax = parseInt(thsInput.attr('max')),
          inputMin = parseInt(thsInput.attr('min'));

    switch (true) {
        case dis.hasClass('btn-items-increase'):
            if (inputVal < inputMax) thsInput.val(inputVal + 1);
            break;
        case dis.hasClass('btn-items-decrease'):
            if (inputVal > inputMin) thsInput.val(inputVal - 1);
            break;
        default:
            return false;
    }
}

const limitInputs = (dis) => {
    let disVal = parseInt(dis.val()),
        disMax = parseInt(dis.attr('max')),
        disMin = parseInt(dis.attr('min'));
    switch (true) {
        case disVal > disMax:
            dis.val(disMax);
            break;
        case disVal < disMin:
            dis.val(disMin);
            break;
        case isNaN(disVal):
            dis.val(disMin);
            break;
    }
};

const toggleUpdateButton = (productId) => {
    let inputToBeUpdated = $(`input.input-items[data-input-product-id-quantity=${productId}]`),
        newInputValue = parseInt(inputToBeUpdated.val()),
        updateQtyWrapper = $(`.update-qty-wrapper[data-update-product-id="${inputToBeUpdated.attr('data-product-id')}"]`),
        disInitialQty = parseInt(updateQtyWrapper.attr('data-initial-quantity'));

    disInitialQty !== newInputValue ? updateQtyWrapper.removeClass('d-none') : updateQtyWrapper.addClass('d-none');
};

const cartFunctions = () => {
    $('.btn-items-increase.buy-change-quantity, .btn-items-decrease.buy-change-quantity')
        .off('click')
        .on('click', function () {
            let dis = $(this);
            addOrSubtractInput(dis);
            toggleUpdateButton(dis.data('productId'));
        });

    $(`.cart-remove, .buy`)
        .off('click')
        .on('click', function () {
            let ths = $(this);
            if(ths.hasClass('cart-remove') && ths.closest('#product-modal').length > 0) productModal.modal('hide');
            computeCartRequest(ths);
    });

    $('.update-qty-btn')
        .off('click')
        .on('click', function () {
            let disData = $(this).parent(),
                inputToBeUpdated = $(`input.input-items[data-input-product-id-quantity=${disData.attr('data-update-product-id')}-${disData.attr('data-added-from-type')}]`);

            computeCartRequest(inputToBeUpdated);
        });

    $(`.cant-updater`)
        .off('change, keyup')
        .on('change, keyup', function () {
            let dis = $(this);

            limitInputs(dis);
            toggleUpdateButton(dis.data('inputProductIdQuantity'));
        });

    $('#applyVoucherButton').off('click').on('click', function() {
        let voucherCodeInput = $('#voucherCodeInput'),
            voucherCode = voucherCodeInput.val(),
            dis = $(this);

        if (voucherCode.length === 0)  return;

        voucherCodeInput.val('');
        dis.attr('disabled', true);

        $.ajax({
            url: cartData.data('applyVoucherPath') + `/${voucherCode}`,
            type: "PUT",
            error: function (req, err) {
                console.error(err);

                dis.removeAttr('disabled');
            },
            success: function (data) {
                if (data.isError) {
                    dis.removeAttr('disabled');

                    console.error(data.errorMessage);
                    alert(data.errorMessage);
                } else {
                    renderDropdown();
                }
            }
        });
    });

    $('#remove-vouchers').off('click').on('click', function() {
        $.ajax({
            url: cartData.data('removeAllVouchers'),
            type: "DELETE",
            error: function (req, err) {
                console.error(err);
            },
            success: function () {
                renderDropdown();
            }
        });
    });
};

$('.visual-change-key')
    .off('change, keyup')
    .on('change, keyup', function () {
        let dis = $(this);
        limitInputs(dis);
        modifyPriceByQty(dis.siblings('.btn-items-increase.visual-change-input'));
    });

$('.btn-items-increase.visual-change-input, .btn-items-decrease.visual-change-input')
    .off('click')
    .on('click', function () {
        let ths = $(this);
        addOrSubtractInput(ths);
        modifyPriceByQty(ths);
});

renderDropdown();

const addLoadingOverlay = (dis) => {
        let loadingId = dis.data('loadingId'),
            loadingBackgroundElement = $(`*[data-loading-id="${loadingId}"]:not(.loading-overlay-trigger):not(.loading-overlay-trigger-no-validation)`);

        loadingBackgroundElement
            .css('position', 'relative')
            .append(`
                <div id="loading" 
                     style="height:${loadingBackgroundElement.outerHeight()*106/100}px; 
                            width:${loadingBackgroundElement.outerWidth()*106/100}px; 
                            margin:${parseInt(loadingBackgroundElement.css('margin'))}px; 
                            padding:${parseInt(loadingBackgroundElement.css('padding'))}px; 
                            top:-${loadingBackgroundElement.outerHeight()*3/100}px;
                            left:-${loadingBackgroundElement.outerWidth()*3/100}px;
                            "
                     data-overlay-id="${loadingId}">
                    <div class="spinner-border text-warning" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>
            `);
};

const hideLoadingOverlay = (dis = $(`*[data-loading-id="${$('#loading').data('overlayId')}"]:not(.loading-overlay-trigger):not(.loading-overlay-trigger-no-validation)`)) => {
    let loadingId = dis.data('loadingId'),
        loadingBackgroundElement = $(`*[data-loading-id="${loadingId}"]:not(.loading-modal)`);

    setTimeout(function() {
        $(`#loading[data-overlay-id="${loadingId}"]`).fadeOut('slow');
        // loadingBackgroundElement.css('position', 'initial');
    }, 700);
};

window.addLoadingOverlay = addLoadingOverlay;
window.hideLoadingOverlay = hideLoadingOverlay;